import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../../axiosConfig";
import { Base_URL } from "../../constant/Constant";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadModal = ({ open, onClose, onUpload }) => {
  const [videoFile, setVideoFile] = React.useState(null);
  const { token } = useSelector((state) => state.auth);

  const handleFileChange = (event) => {
    setVideoFile(event.target.files[0]);
  };

  const handleSubmit = () => {
    if (videoFile) {
      const formData = new FormData();
      formData.append("file", videoFile);
      axios
        .post(`${Base_URL}/api/add-partner`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success) {
            onClose();
            setVideoFile();
            toast.success(`Added successfully!`);
          }
        })
        .catch((err) => toast.error("Something went wrong"));

      onClose();
      setVideoFile(null);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload Photos</DialogTitle>
      <DialogContent>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          style={{ marginTop: "16px", width: "100%" }}
        >
          Upload files
          <VisuallyHiddenInput
            type="file"
            onChange={handleFileChange}
            accept="image/*"
          />
          {videoFile ? videoFile.name : "No file selected"}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="error">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="outlined"
          color="success"
          disabled={!videoFile}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadModal;
