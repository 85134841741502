import React, { useEffect, useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Header from "../../components/Header";
import { toast } from "react-toastify";
import { Base_URL } from "../../constant/Constant";
import { useSelector } from "react-redux";
axios.defaults.withCredentials = true;

const Employees = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${Base_URL}/api/faq-list`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setData(res?.data?.data);
        }
      })
      .catch((err) => {
        toast.error("Something went wrongs");
      });
  };

  const handleDelete = (id) => {
    axios.delete(`${Base_URL}/api/faq-delete/` + id)
      .then((res) => {
        if (res.data.success) {
          getData();
          toast.success(`Deleted successfully!`);
        } 
      })
      .catch((err) => {
        console.log(err);
        alert("Error deleting employee");
      });
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "content",
      headerName: "Content",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Link to={`/faq-add/${row.id}`}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </Link>
          <Button
            onClick={() => {
              handleDelete(row.id);
            }}
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <Box m="20px">
      <Header title="FAQ`s" subtitle="Managing Your FAQ`s" />
      <Box display="flex" justifyContent="flex-end" mb="20px">
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/faq-add")}
        >
          Add FAQ
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data?.map((val) => ({
            id: val?.id,
            title: val?.title,
            content: stripHtml(val?.content),
          }))}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default Employees;
