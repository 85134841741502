import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import UploadModal from "./UploadModal";
import { Base_URL } from "../../constant/Constant";
import { toast } from "react-toastify";
import { getApi } from "../../utils/common";

const PartnerItem = ({ src, onDelete }) => (
  <>
    <Box sx={{ position: "relative", textAlign: "center", p: 2 }}>
      <img
        src={src}
        alt={"company logo"}
        loading="lazy"
        width={150}
        height={80}
      />
    </Box>
    <Box sx={{ position: "relative", textAlign: "center", p: 2 }}>
      <Button
        variant="contained"
        size="medium"
        color="error"
        onClick={onDelete}
        sx={{ mt: 2, fontWeight: "bold", borderRadius: 4 }}
      >
        delete
      </Button>
    </Box>
  </>
);

const PartnerList = ({ videos, onAddVideo, onDeleteVideo }) => (
  <div style={{ margin: "0 auto", maxWidth: "55%" }}>
    <Typography variant="h5" style={{ fontSize: "3rem", marginTop: "3rem" }}>
      Partner Lists
    </Typography>
    <Button
      variant="contained"
      color="primary"
      style={{ marginTop: "2rem", marginBottom: "7rem", fontSize: "1rem" }}
      onClick={onAddVideo}
    >
      Add Partner
    </Button>
    <Grid container spacing={2} justifyContent="center">
      {videos.map((video, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Paper elevation={4} sx={{ p: 2, borderRadius: 2 }}>
            <PartnerItem
              src={`${Base_URL}/images/${video?.image}`}
              onDelete={() => onDeleteVideo(video?.id)}
            />
          </Paper>
        </Grid>
      ))}
    </Grid>
  </div>
);

const PartnerVideo = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getApi({ url: "/partner-list" })
      .then((res) => {
        if (res.success) {
          setData(res.data);
        }
      })
      .catch((err) => toast.error("Something went wrongs"));
  };

  const handleDeleteVideo = (id) => {
    getApi({ url: `/partner-delete/${id}` })
      .then((res) => {
        if (res.success) {
          getData();
          toast.success("Delete successfully");
        }
      })
      .catch((err) => toast.error("Something went wrongs"));
  };

  const handleAddVideo = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    getData();
  };

  return (
    <>
      <PartnerList
        videos={data}
        onAddVideo={handleAddVideo}
        onDeleteVideo={handleDeleteVideo}
      />

      {modalOpen && (
        <UploadModal
          open={modalOpen}
          onClose={handleClose}
          onUpload={handleAddVideo}
        />
      )}
    </>
  );
};

export default PartnerVideo;
