import axios from 'axios';
import { toast } from "react-toastify";
import { store } from "./redux/store";
axios.interceptors.request.use(
  config => {
    let { token } = store.getState()?.auth;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response && response.data?.code === 401) {
      toast.error("Token is expired");
      // localStorage.removeItem("persist:auth");
      // window.location.href = "/login";
    }
    return response;
  },
  (error) => {
    if (
      (error.response && error.response.data?.status === 501) ||
      error.code === "ERR_NETWORK"
    ) {
      toast.error(error.response?.data?.error || "Network error");
      // localStorage.removeItem("persist:auth");
      // window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default axios;
