import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../../axiosConfig";
import { Base_URL } from "../../constant/Constant";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Quill = ReactQuill.Quill;
var Font = Quill.import("formats/font");
Font.whitelist = [
  "sans-serif",
  "Times-New-Roman",
  "Roboto",
  "Raleway",
  "Montserrat",
  "Lato",
  "Rubik",
];
Quill.register(Font, true);

const VisuallyHiddenInput = styled("input")({
  display: "none",
});

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  [{ script: "sub" }, { script: "super" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: Font.whitelist }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["clean"],
];

const modules = {
  toolbar: toolbarOptions,
};

const UploadModal = ({ open, onClose, onUpload }) => {
  const [file, setFile] = useState(null);
  const [type, setType] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const { token } = useSelector((state) => state.auth);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = () => {
    if (!type) {
      toast.error("Video type is mandatory.");
      return;
    }

    if (!file) {
      toast.error("Please upload a file.");
      return;
    }

    const formData = new FormData();
    formData.append("title", videoTitle);
    formData.append("file", file);
    formData.append("type", type);

    axios
      .post(`${Base_URL}/api/video/add`, formData, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.success) {
          onClose();
          setVideoTitle("");
          setFile(null);
          toast.success("Uploaded successfully!");
        }
      })
      .catch(() => toast.error("Something went wrong."));
  };

  const getFileAcceptType = () => {
    return type === 2 ? "image/*,video/*" : "video/*";
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Upload File</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel id="file-type-label">File Type</InputLabel>
            <Select
              labelId="file-type-label"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            >
              <MenuItem value={1}>Banner Videos</MenuItem>
              <MenuItem value={2}>Offers</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle1" gutterBottom>
            Title
          </Typography>
          <ReactQuill
            theme="snow"
            value={videoTitle}
            modules={modules}
            onChange={setVideoTitle}
            placeholder="Enter title..."
          />
        </Box>
        <Box>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            fullWidth
          >
            {file ? file.name : "Upload File (Image or Video)"}
            <VisuallyHiddenInput
              type="file"
              onChange={handleFileChange}
              accept={getFileAcceptType()}
            />
          </Button>
          {file && (
            <Typography variant="body2" color="textSecondary" mt={1}>
              {file.name}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="error">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!file || !type || !videoTitle.trim()}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadModal;
