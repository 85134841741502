import { toast } from "react-toastify";
import axios from "../axiosConfig";

export const getApi = async ({ url }) => {
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    return err?.response?.data;
  }
};

export const getApiByParams = async ({ url, params }) => {
  try {
    const res = await axios.get(url + "/" + params, {
      headers: {
        Accept: "application/json",
      },
    });
    return res.data;
  } catch (err) {
    return err?.response?.data;
  }
};

export const apiPost = async ({ url, values }) => {
  try {
    const res = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
      },
    });
    // ShowToast(res.data?.message, "success");
    return res.data;
  } catch (err) {
    toast.error(err?.response?.data?.error);
    return err?.response?.data;
  }
};
