import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Button, TextField, Typography } from "@mui/material";
import Header from "../../components/Header";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Base_URL } from "../../constant/Constant";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const validationSchema = yup.object().shape({
  title: yup.string("Enter your Question").required(),
  content: yup
    .string("Enter your content")
    .required("Content is a required field"),
});

function AddAndEdit() {
  const { token } = useSelector((state) => state.auth);
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${Base_URL}/api/faq-view/${id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setData(res?.data?.data);
        }
      })
      .catch((err) => {
        toast.error("Something went wrongs");
      });
  };

  const formik = useFormik({
    initialValues: {
      title: data?.title || "",
      content: data?.content || "",
      id: data?.id || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      axios
        .post(`${Base_URL}/api/add-faq`, values, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            navigate("/faq");
            toast.success(`Data added successfully!`);
          }
        })
        .catch((err) => toast.error("Something went wrongs"));
    },
  });

  var toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["link"],
    ["clean"],
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  const handleDescription = (e) => {
    formik.setFieldValue("content", e);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" pt={4}>
      <Header title="Update Employee" />
      <form onSubmit={formik.handleSubmit} style={{ width: "50%" }}>
        <TextField
          fullWidth
          variant="outlined"
          name="title"
          label="Question"
          placeholder="Enter Question"
          autoComplete="off"
          margin="normal"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />

        <ReactQuill
          theme="snow"
          value={formik.values.content}
          modules={module}
          onChange={handleDescription}
        />
        {formik.touched.content && formik.errors.content && (
          <Typography variant="caption" color="error">
            {formik.errors.content}
          </Typography>
        )}
        <Box mt={2}>
          <Button
            type="submit"
            variant="contained"
            style={{ background: "#23A6F0" }}
          >
            {data?.id ? "Update" : "Add"}
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default AddAndEdit;
