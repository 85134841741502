import { Box, Button, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { Base_URL } from "../../../constant/Constant";
import "../../../customStyles.css";
const Quill = ReactQuill.Quill;
var Font = Quill.import("formats/font");
Font.whitelist = [
  "sans-serif",
  "Times-New-Roman",
  "Roboto",
  "Raleway",
  "Montserrat",
  "Lato",
  "Rubik",
];
Quill.register(Font, true);

const Heropage = () => {
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: Font.whitelist }],
    [{ color: [] }, { background: [] }], 
    [{ align: [] }],
    ["clean"],
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const notify = () =>
    toast.success("Successfully updated the website!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const [book, setBook] = useState({
    title: "",
    title2: "",
    title3: "",
    title4: "",
    title5: "",
    desc1: "",
    desc2: "",
    desc3: "",
    desc4: "",
    desc5: "",
    desc6: "",
    desc7: "",
    desc8: "",
    desc9: "",
    desc10: "",
    desc11: "",
    desc12: "",
    desc13: "",
    desc14: "",
    desc15: "",
    desc16: "",
    desc17: "",
    par: "",
    par1: "",
    par2: "",
    par3: "",
    par4: "",
    par5: "",
    par6: "",
    par7: "",
    par8: "",
    par9: "",
    par10: "",
    par11: "",
    par12: "",
    par13: "",
    par14: "",
    par15: "",
    par16: "",
    par17: "",
    par18: "",
  });
  const [images, setImages] = useState({
    cover: null,
    img: null,
    img1: null,
    img2: null,
  });
  const [error, setError] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const location = useLocation();
  const navigate = useNavigate();

  const bookId = location.pathname.split("/")[2];
  const handleImageChange = (e, fieldName) => {
    setImages((prev) => ({ ...prev, [fieldName]: e.target.files[0] }));
  };

  const getUserRole = () => {
    return "superadmin";
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("cover", images.cover);
      formData.append("img", images.img);
      formData.append("img1", images.img1);
      formData.append("img2", images.img2);
      formData.append("title", book.title);
      formData.append("title2", book.title2);
      formData.append("title3", book.title3);
      formData.append("title4", book.title4);
      formData.append("title5", book.title5);
      formData.append("desc1", book.desc1);
      formData.append("desc2", book.desc2);
      formData.append("desc3", book.desc3);
      formData.append("desc4", book.desc4);
      formData.append("desc5", book.desc5);
      formData.append("desc6", book.desc6);
      formData.append("desc7", book.desc7);
      formData.append("desc8", book.desc8);
      formData.append("desc9", book.desc9);
      formData.append("desc10", book.desc10);
      formData.append("desc11", book.desc11);
      formData.append("desc12", book.desc12);
      formData.append("desc13", book.desc13);
      formData.append("desc14", book.desc14);
      formData.append("desc15", book.desc15);
      formData.append("desc16", book.desc16);
      formData.append("desc17", book.desc17);
      formData.append("par", book.par);
      formData.append("par1", book.par1);
      formData.append("par2", book.par2);
      formData.append("par3", book.par3);
      formData.append("par4", book.par4);
      formData.append("par5", book.par5);
      formData.append("par6", book.par6);
      formData.append("par7", book.par7);
      formData.append("par8", book.par8);
      formData.append("par9", book.par9);
      formData.append("par10", book.par10);
      formData.append("par11", book.par11);
      formData.append("par12", book.par12);
      formData.append("par13", book.par13);
      formData.append("par14", book.par14);
      formData.append("par15", book.par15);
      formData.append("par16", book.par16);
      formData.append("par17", book.par17);
      formData.append("par18", book.par18);
      const userRole = getUserRole();
      let updateEndpoint = `/home_page_pending/${bookId}`;
      if (userRole === "superadmin" || userRole === "admin") {
        const status = "approved";
        formData.append("status", status);
        updateEndpoint = `/home_page/${bookId}`;
      }

      await axios.put(`${Base_URL}${updateEndpoint}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      navigate("/edit");
    } catch (err) {
      console.log(err);
      setError(true);
    }
  };

  useEffect(() => {
    axios
      .get(`${Base_URL}/home_page/${bookId}`)

      .then((response) => {
        const existingBook = response.data[0];
        setBook(existingBook);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [bookId]);

  return (
    <Box
      m="20px"
      sx={{
        "& > div": {
          paddingBottom: "30px",
        },
      }}
    >
      <Header title="UPDATE WEBSITE" />
      {/* HERO SECTION START */}
      <Box
        display="grid"
        gap="25px"
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isNonMobile ? undefined : "span 1",
            marginBottom: "30px",
          },
        }}
      >
        <Header
          title="Hero Section"
          subtitle="The images in the hero section at the bottom of this page."
        />
      </Box>
      <Box
        display="grid"
        gap={isMobile ? "70px" : "25px"}
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isMobile ? "span 1" : undefined,
            marginBottom: isMobile ? "60px" : "40px",
          },
        }}
      >
        <ReactQuill
          theme="snow"
          value={book.title}
          modules={module}
          onChange={(value) => setBook((prev) => ({ ...prev, title: value }))}
        />
        <ReactQuill
          value={book.desc1}
          modules={module}
          onChange={(value) => setBook((prev) => ({ ...prev, desc1: value }))}
        />
        <ReactQuill
          value={book.par}
          modules={module}
          onChange={(value) => setBook((prev) => ({ ...prev, par: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.desc2}
          onChange={(value) => setBook((prev) => ({ ...prev, desc2: value }))}
        />
      </Box>

      <Box my={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => {
            notify();
            handleClick(e);
          }}
        >
          Update
        </Button>
      </Box>
      {/* HERO SECTION END */}

      {/* WHYUS SECTION START */}
      <Box
        display="grid"
        gap="25px"
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isNonMobile ? undefined : "span 1",
            marginBottom: "35px",
            marginTop: "35px",
          },
        }}
      >
        <Header title="Whyus Section" subtitle="Update the Why us section" />
      </Box>

      <Box
        display="grid"
        gap={isMobile ? "70px" : "25px"}
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isMobile ? "span 1" : undefined,
            marginBottom: isMobile ? "60px" : "40px",
          },
        }}
      >
        <ReactQuill
          value={book.desc3}
          modules={module}
          onChange={(value) => setBook((prev) => ({ ...prev, desc3: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.desc4}
          onChange={(value) => setBook((prev) => ({ ...prev, desc4: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.title2}
          onChange={(value) => setBook((prev) => ({ ...prev, title2: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.desc5}
          onChange={(value) => setBook((prev) => ({ ...prev, desc5: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par1}
          onChange={(value) => setBook((prev) => ({ ...prev, par1: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.desc6}
          onChange={(value) => setBook((prev) => ({ ...prev, desc6: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par2}
          onChange={(value) => setBook((prev) => ({ ...prev, par2: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.desc7}
          onChange={(value) => setBook((prev) => ({ ...prev, desc7: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par3}
          onChange={(value) => setBook((prev) => ({ ...prev, par3: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.desc8}
          onChange={(value) => setBook((prev) => ({ ...prev, desc8: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par4}
          onChange={(value) => setBook((prev) => ({ ...prev, par4: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.desc9}
          onChange={(value) => setBook((prev) => ({ ...prev, desc9: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par5}
          onChange={(value) => setBook((prev) => ({ ...prev, par5: value }))}
        />
      </Box>

      <Box my={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => {
            notify();
            handleClick(e);
          }}
        >
          Update
        </Button>
      </Box>

      {/* WHYUS SECTION END */}

      {/* PROCESS SECTION START */}
      <Box
        display="grid"
        gap="25px"
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isNonMobile ? undefined : "span 1",
            marginBottom: "35px",
            marginTop: "35px",
          },
        }}
      >
        <Header title="Process Section" subtitle="Update the Process section" />
      </Box>

      <Box
        display="grid"
        gap={isMobile ? "70px" : "25px"}
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isMobile ? "span 1" : undefined,
            marginBottom: isMobile ? "60px" : "40px",
          },
        }}
      >
        <ReactQuill
          modules={module}
          value={book.title3}
          onChange={(value) => setBook((prev) => ({ ...prev, title3: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par6}
          onChange={(value) => setBook((prev) => ({ ...prev, par6: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.desc10}
          onChange={(value) => setBook((prev) => ({ ...prev, desc10: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par7}
          onChange={(value) => setBook((prev) => ({ ...prev, par7: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.desc11}
          onChange={(value) => setBook((prev) => ({ ...prev, desc11: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par8}
          onChange={(value) => setBook((prev) => ({ ...prev, par8: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.desc12}
          onChange={(value) => setBook((prev) => ({ ...prev, desc12: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par9}
          onChange={(value) => setBook((prev) => ({ ...prev, par9: value }))}
        />
      </Box>

      <Box my={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => {
            notify();
            handleClick(e);
          }}
        >
          Update
        </Button>
      </Box>
      {/* PROCESS SECTION END */}

      {/* FEATURES SECTION START */}
      <Box
        display="grid"
        gap="25px"
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isNonMobile ? undefined : "span 1",
            marginBottom: "35px",
            marginTop: "35px",
          },
        }}
      >
        <Header
          title="Features Section"
          subtitle="Update the Features section"
        />
      </Box>

      <Box
        display="grid"
        gap={isMobile ? "70px" : "25px"}
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isMobile ? "span 1" : undefined,
            marginBottom: isMobile ? "60px" : "40px",
          },
        }}
      >
        <ReactQuill
          value={book.title4}
          modules={module}
          onChange={(value) => setBook((prev) => ({ ...prev, title4: value }))}
        />
        <ReactQuill
          value={book.desc13}
          modules={module}
          onChange={(value) => setBook((prev) => ({ ...prev, desc13: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par10}
          onChange={(value) => setBook((prev) => ({ ...prev, par10: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.desc14}
          onChange={(value) => setBook((prev) => ({ ...prev, desc14: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par11}
          onChange={(value) => setBook((prev) => ({ ...prev, par11: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.desc15}
          onChange={(value) => setBook((prev) => ({ ...prev, desc15: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par12}
          onChange={(value) => setBook((prev) => ({ ...prev, par12: value }))}
        />
      </Box>

      <Box my={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => {
            notify();
            handleClick(e);
          }}
        >
          Update
        </Button>
      </Box>
      {/* FEATURES SECTION END */}

      {/* DOWNLOAD SECTION START */}
      <Box
        display="grid"
        gap="25px"
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isNonMobile ? undefined : "span 1",
            marginBottom: "35px",
            marginTop: "35px",
          },
        }}
      >
        <Header
          title="App Download Cta Section"
          subtitle="Update the App Cta section"
        />
      </Box>

      <Box
        display="grid"
        gap={isMobile ? "70px" : "25px"}
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isMobile ? "span 1" : undefined,
            marginBottom: isMobile ? "60px" : "40px",
          },
        }}
      >
        <ReactQuill
          value={book.desc16}
          modules={module}
          onChange={(value) => setBook((prev) => ({ ...prev, desc16: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par13}
          onChange={(value) => setBook((prev) => ({ ...prev, par13: value }))}
        />
      </Box>
      <Box my={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => {
            notify();
            handleClick(e);
          }}
        >
          Update
        </Button>
      </Box>
      {/* DOWNLOAD SECTION END */}

      {/* SERVICES SECTION START */}
      <Box
        display="grid"
        gap="25px"
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isNonMobile ? undefined : "span 1",
            marginBottom: "35px",
            marginTop: "35px",
          },
        }}
      >
        <Header title="Services Section" subtitle="Services section" />
      </Box>

      <Box
        display="grid"
        gap={isMobile ? "70px" : "25px"}
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isMobile ? "span 1" : undefined,
            marginBottom: isMobile ? "60px" : "40px",
          },
        }}
      >
        <ReactQuill
          value={book.title5}
          modules={module}
          onChange={(value) => setBook((prev) => ({ ...prev, title5: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par14}
          onChange={(value) => setBook((prev) => ({ ...prev, par14: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par15}
          onChange={(value) => setBook((prev) => ({ ...prev, par15: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par16}
          onChange={(value) => setBook((prev) => ({ ...prev, par16: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par17}
          onChange={(value) => setBook((prev) => ({ ...prev, par17: value }))}
        />
      </Box>

      <Box my={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => {
            notify();
            handleClick(e);
          }}
        >
          Update
        </Button>
      </Box>
      {/* SERVICES SECTION END */}

      {/* NEWSLETTER SECTION END */}
      <Box
        display="grid"
        gap="25px"
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isNonMobile ? undefined : "span 1",
            marginBottom: "35px",
            marginTop: "35px",
          },
        }}
      >
        <Header title="Newsletter Section" subtitle="Newsletter section" />
      </Box>
        <Box
        display="grid"
        gap={isMobile ? "70px" : "25px"}
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isMobile ? "span 1" : undefined,
            marginBottom: isMobile ? "60px" : "40px",
          },
        }}
      >
        <ReactQuill
          modules={module}
          value={book.desc17}
          onChange={(value) => setBook((prev) => ({ ...prev, desc17: value }))}
        />
        <ReactQuill
          modules={module}
          value={book.par18}
          onChange={(value) => setBook((prev) => ({ ...prev, par18: value }))}
        />
      </Box>
      <Box my={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => {
            notify();
            handleClick(e);
          }}
        >
          Update
        </Button>
      </Box>
      {/* NEWSLETTER SECTION END */}

      <Box
        display="grid"
        gap="25px"
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isNonMobile ? undefined : "span 1",
            marginBottom: "35px",
            marginTop: "35px",
          },
        }}
      >
        <Header
          title="Hero Page Images Section"
          subtitle="update the images used in Hero Page"
        />
      </Box>
      <Box
        display="grid"
        gap="25px"
        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isNonMobile ? undefined : "span 1",
            marginTop: "30px",
          },
        }}
      >
        <div
          style={{
            border: "2px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          {book.cover && (
            <img
              src={`${Base_URL}/images/${book.cover}`}
              alt="Cover"
              style={{ maxWidth: "200px", display: "block", margin: "auto" }}
            />
          )}
          <input
            type="file"
            name="cover"
            onChange={(e) => handleImageChange(e, "cover")}
          />
        </div>

        <div
          style={{
            border: "2px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          {book.img && (
            <img
              src={`${Base_URL}/images/${book.img}`}
              alt="img"
              style={{ maxWidth: "200px", display: "block", margin: "auto" }}
            />
          )}
          <input
            type="file"
            name="img"
            onChange={(e) => handleImageChange(e, "img")}
          />
        </div>

        <div
          style={{
            border: "2px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          {book.img1 && (
            <img
              src={`${Base_URL}/images/${book.img1}`}
              alt="Tago Logos"
              style={{ maxWidth: "200px", display: "block", margin: "auto" }}
            />
          )}
          <input
            type="file"
            name="img1"
            onChange={(e) => handleImageChange(e, "img1")}
          />
        </div>
        <div
          style={{
            border: "2px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          {book.img2 && (
            <img
              src={`${Base_URL}/images/${book.img2}`}
              alt="Tago Logos"
              style={{ maxWidth: "200px", display: "block", margin: "auto" }}
            />
          )}
          <input
            type="file"
            name="img2"
            onChange={(e) => handleImageChange(e, "img2")}
          />
        </div>
      </Box>

      <Box my={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => {
            notify();
            handleClick(e);
          }}
        >
          Update
        </Button>
      </Box>
      {error && <Typography color="error">Something went wrong!</Typography>}
    </Box>
  );
};

export default Heropage;
