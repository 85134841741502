import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import axios from "../../axiosConfig";
import { Base_URL } from "../../constant/Constant";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const CmsUpdate = () => {
  const [cms, setCms] = useState();
  const [title, setTitle] = useState();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { token } = useSelector((state) => state.auth);
  const [val, setData] = useState();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${Base_URL}/api/page/${id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setData(res?.data?.data);
          setCms(res?.data?.data?.content);
          setTitle(res?.data?.data?.title);
        }
      })
      .catch((err) => {
        toast.error("Something went wrongs");
      });
  };

  var toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ["clean"], // remove formatting button
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  const handleSubmit = () => {
    let cleanContent = cms
      .replace(/<br\s*\/?>/gi, "")
      .replace(/&nbsp;/g, " ")
      .trim();
    axios
      .post(
        `${Base_URL}/api/page`,
        {
          title: title,
          id: id,
          content: cleanContent,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          let msg =
            val?.type === 1
              ? "Terms of Service"
              : val?.type === 2
              ? "Privacy Policy"
              : val?.type === 3
              ? "Personal Data Policy"
              : "";
          navigate("/edit");
          toast.success(`${msg} updated successfully!`);
          getData();
        }
      })
      .catch((err) => toast.error("Something went wrongs"));
  };

  return (
    <Box
      m="20px"
      sx={{
        "& > div": {
          paddingBottom: "30px",
        },
      }}
    >
      <Header
        title={`Edit ${
          val?.type === 1
            ? "Terms of Service"
            : val?.type === 2
            ? "Privacy Policy"
            : val?.type === 3
            ? "Personal Data Policy"
            : ""
        }`}
      />
      <Box>
        <TextField
          fullWidth
          id="title"
          name="title"
          label="Enter title"
          variant="outlined"
          InputLabelProps={{ shrink: true, style: { fontSize: 18 } }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Box>
      <Box
        display="grid"
        gap={isMobile ? "70px" : "25px"}
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        sx={{
          "& > div": {
            gridColumn: isMobile ? "span 1" : undefined,
            marginBottom: isMobile ? "60px" : "40px",
          },
        }}
      >
        <ReactQuill
          theme="snow"
          value={cms}
          modules={module}
          onChange={(value) => setCms(value)}
        />
      </Box>
      <Box my={2}>
        <Button variant="contained" color="secondary" onClick={handleSubmit}>
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default CmsUpdate;
