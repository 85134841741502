import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { styled } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import Hero from "../../images/hero.png";
import Blog from "../../images/blog.png";
import Contact from "../../images/contact.png";
import Faq from "../../images/faq.png";
import SignUp from "../../images/signup.png";
import TagoWallet from "../../images/tagowallet.png";
import PrivacyPolicy from "../../images/PrivacyPolicy.png";
import PersonalDataPolicy from "../../images/PersonalDataPolicy.png";
import TermsAndCondition from "../../images/TermsAndCondition.png";
import homeVideo from "../../images/homeVideo.png";
import partnerImage from "../../images/partner.png";
import { Base_URL } from "../../constant/Constant";
import axios from "axios";
axios.defaults.withCredentials = true;
const Container = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: "20px",
  alignItems: "center",
  marginTop: "30px",
});

const BlogPost = styled(Paper)(({ theme }) => ({
  width: "100%",
  padding: "1rem",
  backgroundColor: "#dbf4ff21",
  boxShadow: "0 1.4rem 8rem rgba(0, 0, 0, 0.2)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: ".8rem",
  margin: "10px",
  [theme.breakpoints.up("sm")]: {
    width: "90%",
    display: "flex",
    flexDirection: "row",
  },
}));

const BlogPostImage = styled("div")(({ theme }) => ({
  width: "100%",
  padding: "0 1rem",
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
}));

const Image = styled("img")({
  maxWidth: "100%",
  height: "auto",
});

const BlogPostInfo = styled("div")({
  width: "100%",
  padding: "0 1rem",
});

const BlogPostTitle = styled(Typography)({
  fontSize: "1.6rem",
  margin: "1rem 0",
  textTransform: "uppercase",
  color: "#4facfe",
});

const BlogPostText = styled("p")({
  marginBottom: "2rem",
  fontSize: "1.2rem",
  color: "#fff",
});

const BlogPostCta = styled(Link)(({ theme }) => ({
  display: "inline-block",
  padding: "1.5rem 3rem",
  letterSpacing: "1px",
  textTransform: "uppercase",
  fontSize: "1.2rem",
  color: "#fff",
  textDecoration: "none",
  borderRadius: ".8rem",
  background: `linear-gradient(to right, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  "&:hover": {
    background: `linear-gradient(to right, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
  },
}));

const ContentSection = () => {
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${Base_URL}/edit`).then((res) => {
      if (res.data.Status === "Success") {
        if (
          res.data.role === "superadmin" ||
          res.data.role === "marketer" ||
          res.data.role === "admin"
        ) {
          navigate("/edit");
        } else {
          navigate("/unauthorized");
        }
      } else {
        navigate("/login");
      }
    });
  }, [navigate]);

  const postContent = [
    {
      imgSrc: Hero,
      title: "Hero Page!.",
      text: "It's the first thing visitors see, so make it captivating!",
      id: 1,
    },
    {
      imgSrc: Blog,
      title: "Blog Page",
      text: "This is the blog page, you can write, edit or delete blogs directly from here.",
      id: 1,
    },
    {
      imgSrc: Contact,
      title: "Contact Us Page",
      text: "Edit Contact Us Page content from this page.",
      id: 1,
    },
    {
      imgSrc: Faq,
      title: "FAQ Page",
      text: "Frequently asked question can be edited and updated from here",
      id: 5,
    },
    {
      imgSrc: SignUp,
      title: "Sign Up Page",
      text: "Sign Up Page content can be edited from here",
      id: 1,
    },
    {
      imgSrc: TagoWallet,
      title: "Tago Wallet",
      text: "Tago Wallet Content can be edited from here",
      id: 1,
    },
    {
      imgSrc: PrivacyPolicy,
      title: "Privacy Policy",
      text: "Privacy Policy can be edited from here",
      id: 2,
    },
    {
      imgSrc: TermsAndCondition,
      title: "Terms of Service",
      text: "Terms of Service can be edited from here",
      id: 1,
    },
    {
      imgSrc: PersonalDataPolicy,
      title: "Personal Data Policy",
      text: "Personal Data Policy can be edited from here",
      id: 3,
    },
    {
      imgSrc: homeVideo,
      title: "Home Page Videos",
      text: "Home Page Video can be edited from here",
      id: 1,
    },
    {
      imgSrc: partnerImage,
      title: "Partner",
      text: "Partner can be edited from here",
      id: 1,
    },
  ];

  return (
    <Container>
      {postContent.map((post, index) => (
        <BlogPost key={index}>
          <BlogPostImage>
            <Image src={post.imgSrc} alt="" />
          </BlogPostImage>
          <BlogPostInfo>
            <BlogPostTitle>{post.title}</BlogPostTitle>
            <BlogPostText>{post.text}</BlogPostText>
            <BlogPostCta to={post.id === 5 ? "/faq" : `/update${index + 1}/${post.id}`}>
              <IconButton color="secondary">
                <Edit />
              </IconButton>
            </BlogPostCta>
          </BlogPostInfo>
        </BlogPost>
      ))}
    </Container>
  );
};

export default ContentSection;
