import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import UploadModal from "./UploadModal";
import { Base_URL } from "../../constant/Constant";
import { toast } from "react-toastify";
import { apiPost, getApi } from "../../utils/common";

const VideoItem = ({
  videoSrc,
  title,
  status,
  onDelete,
  onOptionSelect,
  type,
}) => (
  <Box sx={{ position: "relative", textAlign: "center", p: 2 }}>
    {type === 2 && /\.(jpe?g|png|gif)$/i.test(videoSrc) ? (
      <img
        src={videoSrc}
        alt={title}
        style={{ width: "100%", height: "auto", borderRadius: 10 }}
      />
    ) : (
      <video
        src={videoSrc}
        controls
        style={{ width: "100%", height: "auto", borderRadius: 10 }}
      />
    )}

    <Typography
      variant="h6"
      component="div"
      sx={{ mt: 2, fontWeight: 500, fontSize: "1.2rem" }}
      dangerouslySetInnerHTML={{ __html: title }}
    />
    <Button
      variant="contained"
      size="medium"
      onClick={onOptionSelect}
      sx={{ mt: 2, fontWeight: "bold", borderRadius: 4 }}
    >
      {type === 1 ? "Banner" : type === 2 ? "Offers" : ""}
    </Button>

    {status ? (
      <Button
        variant="contained"
        size="medium"
        color="success"
        onClick={onOptionSelect}
        sx={{ mt: 2, fontWeight: "bold", borderRadius: 4 }}
      >
        Active
      </Button>
    ) : (
      type === 1 && (
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={onOptionSelect}
          sx={{ mt: 2, fontWeight: "bold", borderRadius: 4 }}
        >
          Select
        </Button>
      )
    )}

    <Button
      variant="contained"
      size="medium"
      color="error"
      onClick={onDelete}
      sx={{ mt: 2, fontWeight: "bold", borderRadius: 4 }}
    >
      Delete
    </Button>
  </Box>
);

const VideoList = ({ videos, onAddVideo, onDeleteVideo, onMakeDefault }) => (
  <div style={{ margin: "0 auto", maxWidth: "90%" }}>
    <Typography variant="h5" style={{ fontSize: "3rem", marginTop: "4rem" }}>
      Manage Videos and Images
    </Typography>
    <Button
      variant="contained"
      color="primary"
      style={{ marginTop: "2rem", marginBottom: "7rem", fontSize: "1rem" }}
      onClick={onAddVideo}
    >
      Add New
    </Button>

    <Grid container spacing={4} justifyContent="center">
      {videos.map((video, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
            <VideoItem
              videoSrc={`${Base_URL}/images/${video.file}`}
              status={video.status}
              title={video.title}
              type={video.type}
              onOptionSelect={() => onMakeDefault(video.id)}
              onDelete={() => onDeleteVideo(video.id)}
            />
          </Paper>
        </Grid>
      ))}
    </Grid>
  </div>
);

const HomeVideo = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getApi({ url: "/video" })
      .then((res) => {
        if (res.success) {
          setData(res.data);
        }
      })
      .catch(() => toast.error("Failed to fetch data"));
  };

  const handleDeleteVideo = (id) => {
    getApi({ url: `/video/delete/${id}` })
      .then((res) => {
        if (res.success) {
          fetchData();
          toast.success("Deleted successfully");
        }
      })
      .catch(() => toast.error("Failed to delete"));
  };

  const handleAddVideo = () => {
    setModalOpen(true);
  };

  const handleMakeDefault = (id) => {
    apiPost({ url: "/video/make-default", values: { id, status: true } })
      .then((res) => {
        if (res.success) {
          fetchData();
          toast.success("Set as default successfully");
        }
      })
      .catch(() => toast.error("Failed to update status"));
  };

  const handleClose = () => {
    setModalOpen(false);
    fetchData();
  };

  return (
    <>
      <VideoList
        videos={data}
        onAddVideo={handleAddVideo}
        onDeleteVideo={handleDeleteVideo}
        onMakeDefault={handleMakeDefault}
      />
      {modalOpen && <UploadModal open={modalOpen} onClose={handleClose} />}
    </>
  );
};

export default HomeVideo;
