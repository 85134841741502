import React, { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditIcon from "@mui/icons-material/Edit";
import CookieIcon from "@mui/icons-material/Cookie";
import Logo from "../../images/blue_cash.png";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import { useSelector } from "react-redux";
import axios from "../../axiosConfig";
import { Base_URL } from "../../constant/Constant";

const Item = ({ title, to, icon, selected, setSelected, closeSidebar }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => {
        setSelected(title);
        closeSidebar();
      }}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios.get(`${Base_URL}/api/page/checking-token`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false); // Close the sidebar
  };

  const handleMenuItemClick = () => {
    setIsSidebarOpen(false); // Close the sidebar when a menu item is clicked
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 20px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#23A6F0 !important",
        },
        "& .pro-menu-item.active": {
          color: "#23A6F0 !important",
        },
      }}
    >
      <IconButton
        onClick={handleToggleSidebar} // Toggle the sidebar open/close state
        style={{
          position: "fixed",
          zIndex: 9999,
          top: "20px", // Adjust the position from the top
          fontSize: "30px", // Increase the size of the icon
        }}
      >
        <MenuOutlinedIcon style={{ fontSize: "inherit" }} />
      </IconButton>
      {isSidebarOpen && (
        <ProSidebar width="220px" style={{ marginTop: "60px" }}>
          <Menu iconShape="square">
            <Box paddingLeft={isSidebarOpen ? "6%" : undefined}>
              <Typography
                variant="h3"
                color={colors.grey[100]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                <img
                  src={Logo}
                  alt=""
                  width={173}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "6px",
                  }}
                />
              </Typography>
              <Item
                title="Dashboard"
                to="/"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                closeSidebar={closeSidebar}
              />

              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Data
              </Typography>
              <Item
                title="Manage Employee"
                to="/employees"
                icon={<PeopleOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                closeSidebar={closeSidebar}
              />
              <Item
                title="Accepted Cookies"
                to="/cookies"
                icon={<CookieIcon />}
                selected={selected}
                setSelected={setSelected}
                closeSidebar={closeSidebar}
              />
              <Item
                title="Visitors Information"
                to="/visitors"
                icon={<ContactsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                closeSidebar={closeSidebar}
              />
              {/* <Item
                title="Subscribers"
                to="/subscribers"
                icon={<ContactsOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                closeSidebar={closeSidebar}
              /> */}
              <Item
                title="Edit Website"
                to="/edit"
                icon={<EditIcon />}
                selected={selected}
                setSelected={setSelected}
                closeSidebar={closeSidebar}
              />
              <Item
                title="Approve Content"
                to="/approve"
                icon={<ReceiptOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                closeSidebar={closeSidebar}
              />

              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Pages
              </Typography>
              <Item
                title="Add Users"
                to="/form"
                icon={<PersonAddIcon />}
                selected={selected}
                setSelected={setSelected}
                closeSidebar={closeSidebar}
              />
              <Item
                title="profile"
                to="/profile"
                icon={<PersonOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                closeSidebar={closeSidebar}
              />
              <Item
                title="Calendar"
                to="/calendar"
                icon={<CalendarTodayOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                closeSidebar={closeSidebar}
              />
              <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: "15px 0 5px 20px" }}
              >
                Charts
              </Typography>
              <Item
                title="Bar Chart"
                to="/bar"
                icon={<BarChartOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Pie Chart"
                to="/pie"
                icon={<PieChartOutlineOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                closeSidebar={closeSidebar}
              />
              <Item
                title="Line Chart"
                to="/line"
                icon={<TimelineOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                closeSidebar={closeSidebar}
              />
              <Item
                title="Geography Chart"
                to="/geography"
                icon={<MapOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
                closeSidebar={closeSidebar}
              />
             
            </Box>
          </Menu>
        </ProSidebar>
      )}
    </Box>
  );
};

export default Sidebar;
